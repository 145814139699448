
import { defineComponent, defineAsyncComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  components: {
    Dashboard: defineAsyncComponent(
      () => import('@/components/mains/Dashboard.vue')
    ),
    FinancialTips: defineAsyncComponent(
      () => import('@/components/home/FinancialTips.vue')
    ),
  },
  computed: {
    currentMain() {
      return this.$store.state.currentHome;
    },
  },
});
